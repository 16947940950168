import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';

const NotFoundPage = () => (
  <Layout>
    <Head title="404: Not found" />
    <div className="max-w-3xl m-auto">
      <h1>
        <span aria-hidden="true" />
        Ojdå, ute på djupt vatten!
      </h1>
      <p>Något gick snett, kunde tyvärr inte hitta det du såg efter ...</p>
      <p className="italic text-gray-500">(404: Not found)</p>
    </div>
  </Layout>
);

export default NotFoundPage;
